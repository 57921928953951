import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import pdf from "../../images/samkiosk/bx_bxs-file-pdf.svg";
import { GhostBtn } from "../main/main5";
import axios from "axios";
import swal from "sweetalert";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(100.06deg, #0da0a9 -24.01%, #2c4467 131.35%);

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-bottom: 5vw;

    .main-title {
      margin-top: 6vw;
      line-height: 1.2;
      color: #fff;
      display: flex;
      flex-direction: column;
      span {
        font-family: "Pretendard-ExtraBold";
        font-size: 2.1vw; //48px
      }
      p {
        font-family: "Pretendard-SemiBold";
        font-size: 1.25vw; //24px
        margin: 2vw 0;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .main {
      margin: 0 5%;
      background: linear-gradient(47.15deg, #0da0a9 -77.63%, #2c4467 169.12%);
      .main-title {
        line-height: 1.3;
        display: flex;
        flex-direction: column;
        span {
          margin: 20vw 20% 5vw;
          font-size: 6.6vw; //60px
        }
        p {
          font-family: "Pretendard-Medium";
          white-space: pre-wrap;
          margin: 6vw 5%;
          font-size: 4.5vw;
        }
      }
      .main-sub {
      }
    }
  }
`;
const Form = styled.form`
  text-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 37vw;
  .user-info {
    display: flex;
    .inputs {
      display: flex;
      flex-direction: column;
      margin: 1vw 0.6vw;
      width: 17.8vw;
      input[type="text"] {
        border: none;
        padding: 0.8vw 1vw;
        margin-top: 0.4vw;
        border-radius: 8px;
        font-family: "Pretendard-Regular";
        font-size: 0.9vw;
        ::placeholder {
          color: #cccccc;
        }
      }
    }
  }
  .inquiry {
    margin: 1vw 0;
    width: 100%;
    .check-boxs {
      display: flex;
      .check-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2.4vw;
        width: 100%;
      }
    }
    .inquiry-title {
      margin-bottom: 1vw;
      display: block;
    }
    input[type="radio"] {
      margin-right: 0.4vw;
      :not(:first-child) {
        margin-left: 3.8vw;
      }
    }
  }
  .textarea {
    display: flex;
    flex-direction: column;
    margin: 2vw 0;
    width: 100%;
    label {
      margin-bottom: 1vw;
    }
    textarea {
      height: 7vw;
      border: none;
      padding: 0.8vw 1vw;
      border-radius: 8px;
      font-family: "Pretendard-Regular";
      font-size: 0.9vw;
      resize: none;
      ::placeholder {
        color: #cccccc;
      }
    }
  }
  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    input[type="checkbox"] {
      margin-right: 0.4vw;
    }
  }
  label {
    font-family: "Pretendard-SemiBold";
    color: #fff;
    font-size: 0.8vw;
    small {
      margin-left: 0.3vw;
      font-family: "Pretendard-Regular";
      font-size: 0.6vw;
      color: #fe8d5c;
    }
  }
  button {
    background-color: transparent;
    border: 1px solid #ffffff;
    font-size: 1.1vw;
    width: 80%;
    font-family: inherit;
    color: #fff;
    padding: 1vw;
    border-radius: 8px;
    margin: 1.6vw 0;
    cursor: pointer;
  }
  .pdf {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 0.8vw;
    text-decoration: underline;
    img {
      margin-right: 0.2vw;
    }
  }
  .submitButton {
    display: none;
  }
  @media screen and (max-width: 1020px) {
    .inquiry {
      .check-boxs {
        .check-box {
          margin-right: 1.6vw;
        }
      }
    }
    @media screen and (max-width: 875px) {
      .inquiry {
        margin: 2vw 0 0;
        .inquiry-title {
          margin-bottom: 2vw;
        }
        .check-boxs {
          display: grid;
          width: 100%;
          flex-wrap: wrap;
          grid-template-columns: repeat(3, 1fr);
          .check-box {
            margin-bottom: 1vw;
            justify-content: flex-start;
          }
        }
      }
    }

    @media screen and (max-width: 460px) {
      width: 90%;
      .user-info {
        flex-direction: column;
        width: 100%;
        .inputs {
          margin: 4vw 0;
          width: 100%;
          input[type="text"] {
            padding: 2vw 3vw;
            margin-top: 2vw;
            font-family: "Pretendard-Regular";
            font-size: 4vw;
            ::placeholder {
              color: #cccccc;
            }
          }
        }
      }
      .inquiry {
        margin: 4vw 0 0;
        .inquiry-title {
          margin-bottom: 3vw;
        }
        .check-boxs {
          .check-box {
            margin-bottom: 5vw;
          }
        }
      }
      label {
        font-size: 4vw;
        font-family: "Pretendard-Regular";

        small {
          margin-left: 3vw;
          font-size: 3.6vw;
        }
      }
      .textarea {
        margin-bottom: 8vw;
        textarea {
          padding: 4vw 3vw;
          margin-top: 2vw;
          font-family: "Pretendard-Regular";
          font-size: 4vw;
          height: 30vw;
        }
      }
      .checked {
        input[type="checkbox"] {
          margin-right: 1.6vw;
        }
      }
      .submitButton {
        display: flex;
        background: #ffffff;
        border-radius: 8px;
        color: inherit;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 4vw;
        width: 100%;
        font-size: 5vw;
        margin: 4vw;
      }
      .pdf {
        font-size: 4vw;
        margin-bottom: 20vw;
        img {
          margin-right: 2vw;
        }
      }
    }
  }
`;
const GhostBtn1 = styled(GhostBtn)`
  text-align: center;
  display: ${(props) => (props.pc ? "flex" : "none")};
  justify-content: center;
  p {
    color: #fff !important;
  }
  :hover {
    box-shadow: inset 30vw 0px 0px #000;
    border: 1px solid #000;
  }
  @media screen and (max-width: 460px) {
    display: ${(props) => (props.pc ? "none" : "flex")};
  }
`;

function Samkiosk7() {
  const sendEmail = async () => {
    try {
      await axios.post(
        "https://api.samoffice.co.kr/mailSendSamlab",
        info,
        config
      );
    } catch (err) {
      console.log(err);
    }
  };

  const sendMess = async () => {
    // var info = Object.assign({}, info, {
    //   select: typeset,
    //   phone: 
    // });
    try {
      await axios.post("https://api.samoffice.co.kr/signSMS", info, config);
    } catch (err) {
      console.log(err);
    }
  };

  let submitFlag = false;
  const doubleSubmitCheck = () => {
    if (submitFlag) {
      return submitFlag;
    } else {
      submitFlag = true;
      return false;
    }
  };

  const [typeset, setType] = useState("");
  const [check, setCheck] = useState(false);
  const token = "test";
  let config = {
    headers: {
      "X-JWT-TOKEN": token,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      info.purpose = typeset;
      if (
        info.name == "" ||
        info.phone == ""
      ) {
        swal("필수사항을 채워주세요");
        console.log(info);
      } else if (check == false) {
        swal("개인정보 취급방침을 체크해주세요");
        console.log(info);
      } else {
        info.purpose = typeset;
        if (doubleSubmitCheck()) return;

        let response = await axios.post(
          "https://api.samoffice.co.kr/signupSamlab",
          info,
          config
        );

        if (response.status == 200) {
          sendEmail();
          sendMess();
          setInfo({
            ...info,
            name: "",
            phone: "",
            purpose: "",
            content: "",
          });
          swal("신청되었습니다");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleOnChange = ({ target }) =>
    setInfo({ ...info, [target.name]: target.value });
  const sub =
    "고객님의 문의는 소중합니다.\n문의주시면 24시간 내에 답변 드리겠습니다.";
  const [info, setInfo] = useState({
    name: "",
    phone: "",
    purpose: "",
    content: "",
    road: "샘키오스크",
  });
  return (
    <Container>
      <div className="main">
        <div className="main-title">
          <span>지금 바로 구매상담 문의해주세요!</span>
          <p>{sub}</p>
        </div>
        <Form onSubmit={handleSubmit}>
          <div className="user-info">
            <div className="inputs">
              <label for="name">
                이름<small>*필수</small>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                onChange={handleOnChange}
                value={info.name}
                placeholder="성함을 입력해 주세요"
              />
            </div>
            <div className="inputs">
              <label for="tel">
                휴대폰 번호<small>*필수</small>
              </label>
              <input
                id="tel"
                name="phone"
                type="text"
                onChange={handleOnChange}
                value={info.phone}
                placeholder="휴대폰 번호를 입력해 주세요"
              />
            </div>
          </div>
          <div className="inquiry">
            <label className="inquiry-title">
              문의목적
            </label>
            <div className="check-boxs">
              <div className="check-box">
                <input
                  id="check1"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check1">견적문의</label>
              </div>
              <div className="check-box">
                <input
                  id="check2"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check2">실물체험</label>
              </div>
              <div className="check-box">
                <input
                  id="check3"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check3">기술문의</label>
              </div>
              <div className="check-box">
                <input
                  id="check4"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check4">렌탈문의</label>
              </div>
              <div className="check-box">
                <input
                  id="check5"
                  type="radio"
                  name="inquiry"
                  value="default"
                  onChange={({ target }) =>
                    setType(target.nextSibling.textContent)
                  }
                />
                <label for="check5">기타</label>
              </div>
            </div>
          </div>
          <div className="textarea">
            <label for="description">
              문의내용
            </label>
            <textarea
              id="description"
              name="content"
              cols="30"
              rows="5"
              value={info.content}
              onChange={handleOnChange}
              placeholder="문의내용을 입력해 주세요."
            />
          </div>
          <div className="checked">
            <input
              type="checkbox"
              id="ischecked"
              value={check}
              onChange={setCheck}
            />
            <label for="ischecked">개인정보 수집 및 이용을 동의합니다.</label>
          </div>
          <GhostBtn1 pc onSubmit={handleSubmit}>
            <p>문의하기</p>
            <div className="icon-mask"></div>
          </GhostBtn1>
          <button className="submitButton" onSubmit={handleSubmit}>
            <p>문의하기</p>
          </button>
          {/* <button>문의하기</button> */}
          <div className="pdf" style={{ display: "none" }}>
            <img src={pdf} alt="img131"/>
            <p>제품브로슈어 다운로드</p>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default Samkiosk7;
