import * as React from "react";
import styled from "styled-components";
import bg from "../../images/samkiosk/samkiosk3-1.png";
import img from "../../images/samkiosk/samkiosk3.png";
import checked from "../../images/samkiosk/samkiosk3-checked.gif";
// import clicked from "../../images/samkiosk/samkiosk3-clicked.gif";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .bg {
    top: 0vw;
    width: 100%;
    z-index: -99;
    position: absolute;
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 62%;
    margin: 6vw 0 0;
    .main-title {
      margin-top: 6vw;
      font-family: "Pretendard-ExtraBold";
      font-size: 2.5vw; //48px
      white-space: pre-wrap;
      line-height: 1.2;
      color: #000;
      display: flex;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-SemiBold";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 6vw;
    }
    img {
      margin-right: 19%;
      width: 35vw;
    }
  }

  @media screen and (max-width: 460px) {
    overflow-x: hidden;
    .bg {
      height: 140vw;
      object-fit: cover;
      object-position: 0%;
    }
    .main {
      flex-direction: column;
      margin: 15vw 0 10vw;

      width: 100%;
      .imgWrapper {
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
        img {
          width: 90vw;
          margin-right: -5%;
        }
      }
      .main-title {
        width: 90%;
        margin-left: 5%;
        font-size: 6.6vw; //60px
        white-space: pre-wrap;
        line-height: 1.3;
      }
      .main-sub {
        margin: 1.8vw 0 6vw;
        width: 95%;
        font-size: 4.5vw;
      }
    }
  }
`;
const GifImage = styled.div`
  img {
    position: absolute;
    width: 6vw !important;
    top: 12.2vw;
    right: 14.3vw;
    z-index: 99;
  }
  @media screen and (max-width: 460px) {
    img {
      width: 16vw !important;
      top: 72vw;
      right: 36vw;
    }
  }
`;
function Samkiosk3() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.2),
    4: useScrollFadeIn("up", 1.5, 0.6),
  };
  const sub = `스마트폰 터치 한번으로\n해결하는 편한 기기재부팅`;
  return (
    <Container>
      <img alt="img102" className="bg" src={bg} />
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>잔고장은 원격으로</span>
          <span {...animation[1]}>원터치 복구</span>
          <p className="main-sub" {...animation[2]}>
            {sub}
          </p>
        </div>
        <div className="imgWrapper">
          <GifImage>
            <img alt="img103" {...animation[4]} src={checked} />
          </GifImage>
          <img alt="img104" {...animation[3]} src={img} />
        </div>
      </div>
    </Container>
  );
}

export default Samkiosk3;
