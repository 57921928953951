import * as React from "react";
import styled from "styled-components";
import img from "../../images/samkiosk/samkiosk4.png";
import useScrollFadeIn from "../useScrollFadein";
import useScrollMove from "../useScrollMove";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .bg {
    top: 0vw;
    width: 100%;
    z-index: -99;
    position: absolute;
  }
  .main {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8vw;
    align-items: flex-end;

    .main-column {
      :first-child {
        margin-left: 19%;
      }
      :last-child {
        margin-right: 19%;
        .graph {
          position: relative;
          display: flex;
          margin: 0 auto;
          width: 100%;
          img {
            margin: 0 auto;
            width: 24vw;
          }
          h4 {
            z-index: 99;
            position: absolute;
            top: 45%;
            right: 37%;
            color: #222;
            font-size: 2.8vw; //48px
          }
        }
      }
      .main-title {
        margin-top: 6vw;
        font-family: "Pretendard-ExtraBold";
        font-size: 2.5vw; //48px
        white-space: pre-wrap;
        line-height: 1.2;
        color: #000;
        display: flex;
      flex-direction: column;
      }
      .main-sub {
        font-family: "Pretendard-SemiBold";
        color: #555555;
        font-size: 1.25vw; //24px
        margin: 1.8vw 0 3.8vw;
      }
    }
  }
  .info-title {
    font-size: 1.2vw; //20px;;
  }
  .info-sub {
    color: #888;
    font-size: 0.7vw;
    font-family: "Pretendard-Regular";
    margin-top: 0.5vw;
    margin-bottom: 1vw;
  }
  .text-center {
    text-align: center;
  }
  @media screen and (max-width: 460px) {
    line-height: 1.2;
    .main {
      flex-direction: column;
      align-items: flex-start;
      margin: 15vw 0 10vw;
      width: 100%;
      word-break: keep-all;
      .main-column {
        :first-child {
          margin-left: 5%;
          .main-title {
            font-size: 6.6vw; //60px
            white-space: pre-wrap;
            line-height: 1.3;
          }
          .main-sub {
            margin: 6vw 0;
            width: 95%;
            font-size: 4.5vw;
          }
        }
        :last-child {
          /* margin-right: 5%; */
          width: 100%;
          .graph {
            img {
              margin: 0 auto;
              width: 80vw;
            }
            h4 {
              top: 42%;
              right: 37%;
              color: #222;
              font-size: 11vw; //48px
            }
          }
        }
      }
    }
    .info-title {
      font-size: 3.8vw; //20px;;
      text-align: center;
    }
    .info-sub {
      text-align: center;
      word-break: keep-all;
      font-size: 3.6vw;
      width: 64%;
      margin: 3.8vw auto;
    }
  }
`;
const Chart = styled.div`
  display: ${(props) => (props.mobile ? "none" : "block")};
  font-size: 0.8vw;
  .sticks {
    width: 21vw;
    position: relative;
    margin: 0.5vw 0 1.2vw;
  }
  .chart1 {
    .active {
      position: absolute;
      width: 19vw;
      background: linear-gradient(180deg, #9fffe8 0%, #79f6c9 100%);
      height: 1.6vw;
      border: 0.5px solid #ffffff;
      box-sizing: border-box;
      border-radius: 30px;
    }
  }
  .chart2 {
    .active {
      position: absolute;
      width: 2vw;
      background: linear-gradient(180deg, #b09fff 0%, #8d79f6 100%);
      height: 1.6vw;
      border: 0.5px solid #ffffff;
      box-sizing: border-box;
      border-radius: 30px;
    }
  }
  .chart3 {
    .active {
      position: absolute;
      width: 1vw;
      background: linear-gradient(180deg, #ffd572 0%, #febd38 100%);
      height: 1.6vw;
      border: 0.5px solid #ffffff;
      box-sizing: border-box;
      border-radius: 30px;
    }
    margin-bottom: 3vw;
  }
  .back {
    border-radius: 30px;
    background-color: rgba(69, 68, 89, 0.15);
    height: 1.6vw;
  }
  @media screen and (max-width: 460px) {
    display: ${(props) => (props.mobile ? "block" : "none")};
    margin: 0 5%;
    font-size: 3.8vw;
    .sticks {
      width: 100%;
      height: 10vw;
    }
    .chart1,
    .chart2,
    .chart3 {
      p {
        margin-bottom: 1.8vw;
      }
      .active {
        height: 6vw;
      }
    }
    .chart1 {
      .active {
        width: 90%;
      }
    }
    .chart2 {
      .active {
        width: 8%;
      }
    }
    .chart3 {
      .active {
        width: 4%;
      }
    }
    .back {
      height: 6vw;
    }
    .mobile {
      width: 69%;
    }
  }
`;
const Issue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .issue-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5vw;
    p {
      font-size: 0.9vw;
    }
    .box {
      width: 20px;
      height: 20px;
      margin-right: 0.2vw;
      border-radius: 2px;
    }
    .issue1 {
      background: linear-gradient(180deg, #9fffe8 0%, #79f6c9 100%);
    }
    .issue2 {
      background: linear-gradient(180deg, #b09fff 0%, #8d79f6 100%);
    }
    .issue3 {
      background: linear-gradient(180deg, #ffd572 0%, #febd38 100%);
    }
  }
  @media screen and (max-width: 460px) {
    justify-content: space-between;
    margin: 10vw 5% 10vw;
    font-size: 3.6vw;
    .issue-info {
      .box {
        margin-right: 1.6vw;
      }
    }
  }
`;
function Samkiosk4() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.2),
    4: useScrollFadeIn("up", 1, 0.4),
  };
  const moveAnimate = {
    0: useScrollMove("left", 1, 0.2),
    1: useScrollMove("left", 1, 0.2),
  };
  const sub = `저렴한 키오스크의 잦은 잔고장은\n고객이 발길을 끊게하고 매출손실을 불러일으킵니다.`;
  return (
    <Container>
      <div className="main">
        <div className="main-column">
          <div className="main-title">
            <span {...animation[0]}>고객이탈의 주범,</span>
            <span {...animation[1]}>키오스크 오류</span>
            <p className="main-sub" {...animation[2]}>
              {sub}
            </p>
          </div>
          <Chart>
            <div className="chart1">
              <p>잔고장(인식이 안되거나 오작동)</p>
              <div className="sticks">
                <div {...moveAnimate[0]} className="stick active"></div>
                <div className="stick back"></div>
              </div>
            </div>
            <div className="chart2">
              <p>느린 화면 반응</p>
              <div className="sticks">
                <div className="stick active"></div>
                <div className="stick back"></div>
              </div>
            </div>
            <div className="chart3">
              <p>기타</p>
              <div className="sticks">
                <div className="stick active"></div>
                <div className="stick back"></div>
              </div>
            </div>
            <p className="info-title">
              키오스크 사용 시 가장 불편했던 것은 무엇인가요?
            </p>
            <p className="info-sub">
              스터디카페 이용고객 371명 대상 설문조사 (*대학생커뮤니티앱,
              오픈채팅6곳)
            </p>
          </Chart>
        </div>
        <div className="main-column">
          <div className="graph">
            <img alt="img105" {...animation[3]} src={img} />
            <h4 {...animation[4]}>76%</h4>
          </div>
          <div className="graph-info">
            <p className="info-title text-center">
              매출손실/고객이탈의 주 원인은 뭐라고 생각하시나요?
            </p>
            <p className="info-sub text-center">
              스터디카페 점장 183명 대상 자체 설문조사 (*자영업 카페, 오픈채팅
              4곳)
            </p>
          </div>
          <Issue className="issue">
            <div className="issue-info">
              <div className="box issue1" />
              <p>키오스크이슈</p>
            </div>
            <div className="issue-info">
              <div className="box issue2" />
              <p>부정사용자</p>
            </div>
            <div className="issue-info">
              <div className="box issue3" />
              <p>기타</p>
            </div>
          </Issue>
          <Chart mobile>
            <p className="info-title">
              키오스크 사용 시 가장 불편했던 것은 무엇인가요?
            </p>
            <p className="info-sub mobile">
              스터디카페 이용고객 371명 대상 설문조사 (*대학생커뮤니티앱,
              오픈채팅6곳)
            </p>
            <div className="chart1">
              <p>잔고장(인식이 안되거나 오작동)</p>
              <div className="sticks">
                <div className="stick active"></div>
                <div className="stick back"></div>
              </div>
            </div>
            <div className="chart2">
              <p>느린 화면 반응</p>
              <div className="sticks">
                <div className="stick active"></div>
                <div className="stick back"></div>
              </div>
            </div>
            <div className="chart3">
              <p>기타</p>
              <div className="sticks">
                <div className="stick active"></div>
                <div className="stick back"></div>
              </div>
            </div>
          </Chart>
        </div>
      </div>
    </Container>
  );
}

export default Samkiosk4;
