import * as React from "react";
import styled from "styled-components";
import img from "../../images/samkiosk/samkiosk1.png";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -99;
  }
  .main {
    position: absolute;
    width: 100%;
    color: #fff;
    .main-title {
      margin-left: 19%;
      font-family: "Pretendard-ExtraBold";
      font-size: 4.2vw; //60px
      white-space: pre-wrap;
      line-height: 1.2;
      display: flex;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-SemiBold";

      font-size: 1.4vw;
      margin-top: 2vw;
    }
  }
  @media screen and (max-width: 460px) {
    img {
      height: 100vh;
      width: 100%;
      object-fit: cover;
      object-position: 45% 100%;
    }
    .main {
      .main-title {
        margin-left: 5%;
        font-size: 10.5vw; //60px
        white-space: pre-wrap;
        line-height: 1.3;
        word-break: keep-all;
      }
      .main-sub {
        font-size: 4.5vw;
      }
    }
  }
  /* @media screen and (max-width: 360px) {
    .main {
      .main-title {
        font-size: 10.5vw; //60px
      }
      .main-sub {
        font-size: 4.5vw;
      }
    }
  } */
`;

function Samkiosk1() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };
  return (
    <Container>
      <img alt="img98" src={img} alt="img" />
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>사장님,</span>
          <span {...animation[1]}>걱정마시고 퇴근하세요.</span>
          <p className="main-sub" {...animation[2]}>
            매장은 샘키오스크가 책임질께요 :)
          </p>
        </div>
      </div>
    </Container>
  );
}

export default Samkiosk1;
