import * as React from "react";
import styled from "styled-components";
import mapBg from "../../images/samkiosk/samkiosk6.png";
import img1 from "../../images/samkiosk/samkiosk6-1.png";
import img2 from "../../images/samkiosk/samkiosk6-2.png";
import img3 from "../../images/samkiosk/samkiosk6-3.png";
import img4 from "../../images/samkiosk/samkiosk6-4.png";
import img5 from "../../images/samkiosk/samkiosk6-5.png";
import img6 from "../../images/samkiosk/samkiosk6-6.png";
import Slider from "react-slick";
import useScrollFadeIn from "../useScrollFadein";

const Container = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10vw 0 5vw;
    .main-title {
      text-align: center;
      font-family: "Pretendard-ExtraBold";
      font-size: 2.5vw; //48px
      white-space: pre-wrap;
      line-height: 1.2;
      color: #000;
      display: flex;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-SemiBold";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 6vw;
    }
  }
  .map {
    position: relative;
    img {
      width: 25vw;
    }
    .review {
      position: absolute;
      width: 13.5vw;
      background-color: #fff;
      box-shadow: 0px 0px 20px rgba(15, 28, 43, 0.15);
      border-radius: 8px;
      padding: 0.6vw 1vw;
      font-family: "Pretendard-Regular";
      font-size: 14px;
      line-height: 1.4;

      :nth-child(2) {
        top: 0;
        right: -1vw;
      }
      :nth-child(3) {
        top: 5vw;
        right: 20vw;
        width: 12vw;
      }
      :nth-child(4) {
        top: 15vw;
        right: -8vw;
        width: 18vw;
      }
      :nth-child(5) {
        top: 18vw;
        right: 17vw;
        width: 16vw;
      }
      :nth-child(6) {
        top: 29vw;
        right: -5vw;
        width: 13vw;
      }
      p {
        color: #555;
        strong {
          color: #222;
          font-family: "Pretendard-bold";
        }
      }
      small {
        color: #999;
        font-size: 12px;
      }
    }
  }
  .partners {
    .slick-list {
      width: 61.4vw !important;
      .slick-track {
        .slick-slide {
          transition: opacity 0.2s ease-in;
          :not(:hover) {
            opacity: 0.6;
          }
          div {
            img {
              margin: 5vw 1vw 0;
              width: 8vw !important;
            }
          }
        }
      }
    }
  }
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  @media screen and (max-width: 460px) {
    .pc {
      display: none !important;
    }
    .mobile {
      display: block;
    }
    .main {
      margin: 0 5%;
      .main-title {
        margin: 20vw 0 5vw;
        font-size: 6.6vw; //60px
        white-space: pre-wrap;
        line-height: 1.3;
      }
      .main-sub {
        margin: 6vw 5%;
        font-size: 4.5vw;
      }
    }
    .map {
      margin: 10vw 0 40vw;
      img {
        width: 75vw;
      }
      .review {
        width: 45vw;
        padding: 3vw 5vw;
        font-size: 12px;
        :nth-child(2) {
          top: -13vw;
          right: 2vw;
        }
        :nth-child(3) {
          top: 20vw;
          right: 24vw;
          width: 45vw;
        }
        :nth-child(4) {
          top: 51vw;
          right: -6vw;
          width: 45vw;
        }
        :nth-child(5) {
          top: 83vw;
          right: 24vw;
          width: 45vw;
        }
        :nth-child(6) {
          top: 114vw;
          right: -5vw;
          width: 45vw;
        }
      }
    }
    .partners {
      .slick-list {
        .slick-track {
          .slick-slide {
            :not(:hover) {
              opacity: 1;
            }

            div {
              img {
                width: 40vw !important;
                height: fit-content;
                object-fit: contain;
                margin: 15vw 0;
              }
            }
          }
        }
      }
    }
  }
  /* .partners {
      display: flex;
      overflow-x: hidden;
      margin: 10vw 5% 20vw;
      img {
        width: 30vw;
        height: fit-content;
        object-fit: contain;
        margin: 0 5vw;
      }
    }
  } */
`;

function Samkiosk6() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.3),
    4: useScrollFadeIn("up", 1, 0.4),
    5: useScrollFadeIn("up", 1, 0.5),
    6: useScrollFadeIn("up", 1, 0.6),
  };
  const settings = {
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    infinite: true,
    slidesToShow: 5.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  const settings1 = {
    className: "center",
    centerPadding: "0px",
    centerMode: true,
    infinite: true,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  return (
    <Container>
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>서울에서 제주까지</span>
          <span {...animation[1]}>전국 100여개의 협력사에서 사용합니다</span>
          <p className="main-sub">
            전국의 수많은 구매자께서 샘랩의 키오스크를 사용하시고 만족스런
            후기를 남겨주셨습니다.
          </p>
        </div>
        <div className="map">
          <img src={mapBg} alt="img117"/>
          <div {...animation[2]} className="review">
            <p>
              다른 키오스크는 잔고장이 심해서 힘들었는데,{" "}
              <strong>샘랩은 잔고장이 없어서</strong> 스터디카페 운영하기 정말
              편해졌어요.
            </p>
            {/* <p>다른 키오스크는 잔고장이 심해서 </p>
            <p>
              힘들었는데,<strong>샘랩은 잔고장이 없어서</strong>
            </p>
            <p>스터디카페 운영하기 정말 편해졌어요.</p> */}
          </div>
          <div {...animation[3]} className="review">
            <p>
              <strong>학부모가 학생 입퇴소도 알 수 있어서</strong> 다른
              프랜차이즈 많이 알아봤는데 차별화된 부분 같습니다.
            </p>
            {/* <strong>학부모가 학생 입퇴소도 알 수 있어서</strong>
            <p>
              다른 프랜차이즈 많이 알아봤는데
            </p>
            <p>차별화된 부분 같습니다.</p> */}
            <small>*샘클라우드기능</small>
          </div>
          <div {...animation[4]} className="review">
            <p>
              지문으로 입퇴장이 가능해서 핸드폰, 바코드 없이도 입출입이 가능한게{" "}
              <strong>불필요한 문의건을 줄이는데 큰 역할</strong>을 했습니다
            </p>
            {/* <p>지문으로 입퇴장이 가능해서 핸드폰,</p>
            <p>
              바코드 없이도 입출입이 가능한게
            </p>
            <p><strong>불필요한 문의건을 줄이는데 큰 역할</strong>을 했습니다</p> */}
          </div>
          <div {...animation[5]} className="review">
            <p>
              클라우드 관리시스템으로 영수증 출력, 카드취소, 지문등록까지
              온라인으로 가능하니{" "}
              <strong>매장 갈 일이 50%나 줄어들었어요.</strong>
            </p>
            {/* <p>클라우드 관리시스템으로 영수증 출력,</p>
            <p>
              카드취소, 지문등록까지 온라인으로 가능하니
            </p>
            <strong>매장 갈 일이 50%나 줄어들었어요.</strong> */}
          </div>
          <div {...animation[6]} className="review">
            <p>
              양방향 키오스크라{" "}
              <strong> 시간 초과돼도 나갈 때 추가요금 결제</strong>하고 나가도록
              되어있어서 마음이 놓여요.
            </p>
            {/* <p>
              양방향 키오스크라 <strong>시간 초과돼도</strong>
            </p>
            <p>
              <strong>나갈 때 추가요금 결제</strong>하고 나가도록
            </p>
            <p>되어있어서 마음이 놓여요 .</p> */}
          </div>
        </div>
        <Slider {...settings} className="partners pc">
          <img src={img1} alt="img118"/>
          <img src={img2} alt="img119"/>
          <img src={img3} alt="img120"/>
          <img src={img4} alt="img121"/>
          <img src={img5} alt="img122"/>
          <img src={img6} alt="img123"/>
        </Slider>
        <Slider {...settings1} className="partners mobile">
          <img src={img1} alt="img124"/>
          <img src={img2} alt="img125"/>
          <img src={img3} alt="img126"/>
          <img src={img4} alt="img127"/>
          <img src={img5} alt="img128"/>
          <img src={img6} alt="img130"/>
        </Slider>
      </div>
    </Container>
  );
}

export default Samkiosk6;
