import * as React from "react";
import styled from "styled-components";
import bg from "../../images/samkiosk/samkiosk5.png";
import bgMobile from "../../images/samkiosk/samkiosk5-mobile.png";
import icon1 from "../../images/samkiosk/samkiosk5-1.svg";
import icon2 from "../../images/samkiosk/samkiosk5-2.png";
import icon3 from "../../images/samkiosk/samkiosk5-3.svg";
import icon4 from "../../images/samkiosk/samkiosk5-4.svg";
import icon5 from "../../images/samkiosk/samkiosk5-5.svg";
import icon6 from "../../images/samkiosk/samkiosk5-6.svg";
import icon7 from "../../images/samkiosk/samkiosk5-7.svg";
import arrow from "../../images/arrow_w.svg";
import arrowActive from "../../images/arrow-active.svg";
import useScrollFadeIn from "../useScrollFadein";
import Slider from "react-slick";
import { Link } from "gatsby";
import { GhostBtn } from "../main/main5";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  a {
    cursor: auto;
  }
  .bg {
    top: 0;
    width: 100%;
    z-index: -99;
    position: absolute;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    .main-title {
      text-align: center;
      margin-top: 6vw;
      font-family: "Pretendard-ExtraBold";
      font-size: 2.5vw; //48px
      white-space: pre-wrap;
      line-height: 1.2;
      color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-SemiBold";
      font-size: 1.2vw; //24px
      margin: 1.8vw 0 1vw;
    }
  }
  .link {
    font-family: "Pretendard-Medium";
    font-size: 0.9vw;
    text-decoration: underline;
  }
  .mobile {
    display: none;
  }
  .pc {
    display: block;
  }
  @media screen and (max-width: 460px) {
    justify-content: flex-start;
    .mobile {
      display: block;
    }
    .pc {
      display: none;
    }
    height: 170vw;
    .bg {
      width: 100%;
      object-fit: cover;
    }
    .main {
      margin: 0 5%;
      .main-title {
        margin: 20vw 0 5vw;
        font-size: 6.6vw; //60px
        white-space: pre-wrap;
        line-height: 1.3;
      }
      .main-sub {
        font-family: "Pretendard-medium";
        margin: 6vw 5%;
        font-size: 4.5vw;
      }
    }
    .link {
      font-size: 3vw;
    }
  }
`;
const Icons = styled.div`
  white-space: pre-wrap;
  display: ${(props) => (props.pc ? "flex" : "none")};
  scrollbar-width: none !important;
  margin-top: 2vw;
  margin-bottom: 6vw;
  width: 100%;
  ::-webkit-scrollbar {
    display: none !important;
  }
  .icon {
    width: 15vw;
    height: 15vw;

    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 2vw;
    margin: 0.6vw;
    p {
      line-height: 1.2;
      overflow: hidden;
      font-size: 1.2vw;
    }
  }
  img {
    height: 5vw;
    object-fit: contain;
    margin-bottom: 2vw;
  }
  .slick-slider {
    margin: 0 !important;
  }
  .slick-list {
    padding: 0 18.5% !important;
  }
  .slick-active {
    opacity: 0.8 !important;
  }
  .slick-center {
    opacity: 1 !important;
  }
  .slick-slide {
    transition: opacity 1s ease-in-out;
    opacity: 0.2;
    /* :not(.slick-active) {
    } */

    .icon {
      :hover {
        background-color: #54ffeb;
      }
    }
    :nth-child(2n) {
      .icon {
        margin-top: 5vw;
      }
    }
  }

  @media screen and (max-width: 460px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    margin-top: 10vw;
    .icon {
    }

    .slick-slider {
      margin: 0 !important;
    }
    .slick-list {
      padding: 5% 18.5% !important;
    }
    .slick-slide {
      transition: opacity 0.2s ease-in-out;
      :not(.slick-center) {
        opacity: 0.4;
      }

      transition: opacity 0.2s ease-in-out;
      .icon {
        width: 54vw;
        height: 54vw;
        border-radius: 8vw;
        margin: -4vw 2vw;
        img {
          width: 100%;
          height: 20vw;
          object-fit: contain;
          margin-bottom: 6vw;
        }

        p {
          font-size: 3.8vw;
        }
      }
      :nth-child(2n) {
        .icon {
          margin-top: 5vw;
        }
      }
    }
  }
`;
const GhostBtn1 = styled(GhostBtn)`
  border: 1px solid #fff;
  margin: 1.6vw 0;
  p {
    color: #fff;
  }
  transition: all 0.5s ease-in-out;
  :hover {
    border: 1px solid #000;
  }
`;

function Samkiosk5() {
  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "13%",
    slidesToShow: 5.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  const settings1 = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "13%",
    slidesToShow: 1.5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: "linear",
  };
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
  };
  const sub =
    "샘랩 키오스크는 본사개발팀이 직접 개발을 하고 본사와 직영점 우선 테스트를 거쳐\n피드백 후 지속적인 업데이트를 해드립니다. ";
  const p1 = "잔고장이 없어\n인건비, 관리비 손실 방지";
  const p2 = "클라우드 기반 시스템으로\n지속적인 업데이트";
  const p3 = "고객이슈\n24시간 대응";
  const p4 = "열화상 감지 기능으로\n안전한 입장";
  const p5 = "직관적인 UI로\n높은 사용자 만족도 ";
  const p6 = "장치 구분없이\n 언제 어디서든 매장관리";
  const p7 = "고객용 프로그램으로\n이용권과 학습관리를 동시에";

  return (
    <Container>
      <img className="bg pc" src={bg} alt="img" />
      <img className="bg mobile" src={bgMobile} alt="img" />
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>나 대신 일하는 키오스크,</span>
          <span {...animation[1]}>아무거나 두지 마세요</span>
          <p className="main-sub" {...animation[2]}>
            {sub}
          </p>
          <Link to="/product-spec">
            <GhostBtn1 pc>
              <p>제품 스펙 자세히 보기</p>
              <div className="icon-mask">
                <object className="icon" data={arrow} />
                <object className="icon" data={arrowActive} />
              </div>
            </GhostBtn1>
          </Link>
        </div>
      </div>
      <Icons pc>
        <Slider {...settings}>
          <div className="icon">
            <img src={icon1} />
            <p>{p1}</p>
          </div>
          <div className="icon">
            <img src={icon2} />
            <p>{p2}</p>
          </div>
          <div className="icon">
            <img src={icon3} />
            <p>{p3}</p>
          </div>
          <div className="icon">
            <img src={icon4} />
            <p>{p4}</p>
          </div>
          <div className="icon">
            <img src={icon5} />
            <p>{p5}</p>
          </div>
          <div className="icon">
            <img src={icon6} />
            <p>{p6}</p>
          </div>
          <div className="icon">
            <img src={icon7} />
            <p>{p7}</p>
          </div>
          <div className="icon">
            <img src={icon1} />
            <p>{p1}</p>
          </div>
        </Slider>
      </Icons>
      <Icons mobile>
        <Slider {...settings1}>
          <div className="icon">
            <img src={icon1} />
            <p>{p1}</p>
          </div>
          <div className="icon">
            <img src={icon2} />
            <p>{p2}</p>
          </div>
          <div className="icon">
            <img src={icon3} />
            <p>{p3}</p>
          </div>
          <div className="icon">
            <img src={icon4} />
            <p>{p4}</p>
          </div>
          <div className="icon">
            <img src={icon5} />
            <p>{p5}</p>
          </div>
          <div className="icon">
            <img src={icon6} />
            <p>{p6}</p>
          </div>
          <div className="icon">
            <img src={icon7} />
            <p>{p7}</p>
          </div>
          <div className="icon">
            <img src={icon1} />
            <p>{p1}</p>
          </div>
        </Slider>
      </Icons>
    </Container>
  );
}

export default Samkiosk5;
