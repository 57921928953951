import * as React from "react";
import Layout from "../components/layout/layout";
import styeld from "styled-components";
import Samkiosk1 from "../components/samkiosk/samkiosk1";
import Samkiosk2 from "../components/samkiosk/samkiosk2";
import Samkiosk3 from "../components/samkiosk/samkiosk3";
import Samkiosk4 from "../components/samkiosk/samkiosk4";
import Samkiosk5 from "../components/samkiosk/samkiosk5";
import Samkiosk6 from "../components/samkiosk/samkiosk6";
import Samkiosk7 from "../components/samkiosk/samkiosk7";
import { Helmet } from "react-helmet";

const Containar = styeld.div`
`;
const pageName = "샘키오스크";
const tag_title = `${pageName} - 샘랩`;
const tag_description = `샘랩의 ${pageName} 페이지 입니다.`;
// markup
const Samkiosk = () => {
  return (
    <Layout>
      <Helmet>
        <title>{tag_title}</title>
        <meta name="title" content={tag_title} />
        <meta name="description" content={tag_description} />
        <meta property="og:title" content={tag_title} />
        <meta property="og:description" content={tag_description} />
        <meta property="og:url" content="https://samlab.co.kr/samkiosk" />
        <meta property="twitter:title" content={tag_title} />
        <meta property="twitter:description" content={tag_description} />
        <meta property="twitter:url" content="https://samlab.co.kr/samkiosk" />
      </Helmet>
      <Samkiosk1 />
      <Samkiosk2 />
      <Samkiosk3 />
      <Samkiosk4 />
      <Samkiosk5 />
      <Samkiosk6 />
      <Samkiosk7 />
    </Layout>
  );
};

export default Samkiosk;
