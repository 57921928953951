import * as React from "react";
import styled from "styled-components";
import img from "../../images/aboutus/about1.png";
import useScrollFadeIn from "../useScrollFadein";
import icon1 from "../../images/samkiosk/samkiosk2-1.svg";
import icon2 from "../../images/samkiosk/samkiosk2-2.svg";
import icon3 from "../../images/samkiosk/samkiosk2-3.svg";
import iconArrow from "../../images/samkiosk/samkiosk2-arrow.svg";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 6vw 0;
    .main-title {
      text-align: center;
      font-family: "Pretendard-ExtraBold";
      font-size: 2.5vw; //48px
      white-space: pre-wrap;
      line-height: 1.2;
      color: #000;
      display: flex;
      flex-direction: column;
    }
    .main-sub {
      font-family: "Pretendard-SemiBold";
      color: #555555;
      font-size: 1.25vw; //24px
      margin: 1.8vw 0 6vw;
    }
  }
  @media screen and (max-width: 460px) {
    .main {
      margin: 25vw 0 10vw;
      word-break: keep-all;
      .main-title {
        font-size: 6.6vw; //60px
        white-space: pre-wrap;
        line-height: 1.3;
      }
      .main-sub {
        width: 80%;
        margin: 5vw auto;
        font-size: 4.5vw;
      }
    }
  }
`;
const Icons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-family: "Pretendard-Medium";
      color: #555555;
      font-size: 1.2vw;
    }
  }
  .arrow {
    width: 3vw;
    margin-bottom: 1.5vw;
  }
  .iconWrapper {
    width: 11.5vw;
    height: 11.5vw;
    box-shadow: 0px 0px 20px rgba(15, 28, 43, 0.15);
    border-radius: 50%;
    margin: 0 2.4vw 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      width: 38%;
    }
  }
  @media screen and (max-width: 460px) {
    flex-direction: column;
    margin-bottom: 10vw;
    .icon {
      p {
        font-size: 3.6vw;
      }
    }
    .arrow {
      width: 10vw;
      margin-top: 10vw;
      transform: rotate(90deg);
    }
    .iconWrapper {
      width: 150px;
      height: 150px;
      margin: 10vw 0 6vw;
    }
  }
`;
function Samkiosk2() {
  const animation = {
    0: useScrollFadeIn("up", 1, 0),
    1: useScrollFadeIn("up", 1, 0.1),
    2: useScrollFadeIn("up", 1, 0.2),
    3: useScrollFadeIn("up", 1, 0.4),
    4: useScrollFadeIn("up", 1, 0.6),
    5: useScrollFadeIn("up", 1, 0.8),
  };
  return (
    <Container>
      <div className="main">
        <div className="main-title">
          <span {...animation[0]}>입장손님뿐만 아니라</span>
          <span {...animation[1]}>퇴실손님까지 관리합니다.</span>
          <p className="main-sub" {...animation[2]}>
            양방향 키오스크로 초과이용한 퇴실 손님은 추가요금 결제 후 퇴실
          </p>
        </div>
        <Icons>
          <div className="icon">
            <div className="iconWrapper">
              <img alt="img99" {...animation[3]} src={icon1} />
            </div>
            <p>이용권 결제 후 입실</p>
          </div>
          <object className="arrow" data={iconArrow} />
          <div className="icon">
            <div className="iconWrapper">
              <img alt="img100"{...animation[4]} src={icon2} />
            </div>
            <p>이용시간 외 초과이용</p>
          </div>
          <object className="arrow" data={iconArrow} />
          <div className="icon">
            <div className="iconWrapper">
              <img alt="img101" {...animation[5]} src={icon3} />
            </div>
            <p>추가결제 후 퇴실</p>
          </div>
        </Icons>
      </div>
    </Container>
  );
}

export default Samkiosk2;
